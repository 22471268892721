<template>
  <draggable
    :list="basicComponents"
    item-key="type"
    tag="div"
    :group="{ name: 'basic', pull: 'clone', put: false }"
    :sort="false"
    ghostClass="ghost"
  >
    <template #item="{ element }">
      <a-button block style="cursor: move;" class="mb-2">
        {{$t('formConfig.widget.' + element.type)}}
      </a-button>
    </template>
  </draggable>
  <draggable
    :list="layoutComponents"
    item-key="type"
    tag="div"
    :group="{ name: 'layout', pull: 'clone', put: false }"
    :sort="false"
    ghostClass="ghost"
  >
    <template #item="{ element }">
      <a-button block style="cursor: move;" class="mb-2">
        {{$t('formConfig.widget.' + element.type)}}
      </a-button>
    </template>
  </draggable>
</template>

<script>
import Draggable from "vuedraggable";
import {
  basicComponents,
  layoutComponents,
} from "@/components/form-generation/designer/js/components-config.js";

export default {
  name: "sp-form-designer-item-list",
  components: {
    Draggable,
  },
  data() {
    return {
      basicComponents,
      layoutComponents,

    }
  }
}
</script>

<style>

</style>