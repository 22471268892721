<template>
  <div>
    <a-form>
      <draggable
        class="main-draggable"
        v-model="widgetList"
        item-key="key"
        :group="{ name: 'basic', pull: ['basic','layout'], put: ['basic','layout'] }"
        ghostClass="ghost"
        :animation="200"
        handle=".drag-widget"
        @add="onWidgetAdd"
        :move="checkMove"
      >
        <template #item="{ element, index }">
          <LayoutItem 
            v-if="element.isLayout"
            class="widget-view widget-view-layout"
            :element="element"
            :class="{ active: selectedWidgetKey === element.key }"
            @click="onWidgetSelect(index)"
          >
            <template #item="{ item }">
              <draggable
                class="widget-view-layout-drop"
                :component-data="{ isLayout: true }"
                v-model="item.list"
                item-key="key"
                :no-transition-on-drag="true"
                :group="{ name: 'basic' }"
                ghostClass="ghost"
                handle=".drag-widget"
                :animation="200"
                @add="onWidgetAdd($event, item.list)"
              >
                <template #item="{ element: el, index: i }">
                  <div
                    class="widget-view"
                    :class="{ active: selectedWidgetKey === el.key }"
                    @click.stop="onWidgetSelect(i, item.list)"
                  >
                    <Item :element="el" />
                    <Toolbar 
                      v-if="selectedWidgetKey === el.key" 
                      canClone
                      @delete="onWidgetDelete(i, item.list)"
                      @clone="onWidgetClone(i, item.list)"
                    />
                  </div>
                </template>
              </draggable>
            </template>
            <template #after>
              <Toolbar 
                v-if="selectedWidgetKey === element.key" 
                @delete="onWidgetDelete(index)"
              />
            </template>
          </LayoutItem>
          <div
            v-else
            class="widget-view"
            :class="{ active: selectedWidgetKey === element.key }"
            @click.stop="onWidgetSelect(index)"
          >
            <Item :element="element" />
            <Toolbar 
              v-if="selectedWidgetKey === element.key" 
              canClone
              @clone="onWidgetClone(index)"
              @delete="onWidgetDelete(index)"
            />
          </div>
        </template>
      </draggable>
    </a-form>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import { cloneDeep } from "lodash";
import LayoutItem from "../sp-form-generator-layout-item.vue";
import Item from "../sp-form-generator-item.vue";
import Toolbar from "./sp-form-designer-edit-toolbar.vue";
import useSharedState from "./js/shared-state";
import { getKey } from "./js/utils";

export default {
  name: "sp-form-designer-item-form",
  components: {
    Draggable,
    Item,
    LayoutItem,
    Toolbar
  },
  setup() {
    const { widgetList, formConfig, selectedWidget } = useSharedState();
    return {
      widgetList,
      formConfig,
      selectedWidget,
    };
  },
  mounted() {
    document.body.ondrop = function (event) {
      let isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
      if (isFirefox) {
        event.preventDefault();
        event.stopPropagation();
      }
    };
  },
  computed: {
    selectedWidgetKey() {
      return this.selectedWidget?.key;
    }
  },
  methods: {
    checkMove(e) {
      if (e.draggedContext?.element?.isLayout && 
        e.relatedContext?.component?.componentData?.isLayout)
        return false;
      return true;
    },
    onWidgetSelect(index, widgetList) {
      if (!widgetList)
        widgetList = this.widgetList;
        
      this.selectedWidget = widgetList[index];
    },
    onWidgetAdd(evt, widgetList) {
      if (!widgetList)
        widgetList = this.widgetList;

      const newIndex = evt.newIndex;
      const key = getKey();

      let item = cloneDeep(widgetList[newIndex]);
      
      widgetList[newIndex] = item;
      if (!item.options) item.options = {};
      item.key = key;

      switch(item.type) {
        case "radio":
        case "checkbox":
        case "select":
          item.options = {
            ...item.options,
            ...item.options.options.map((item) => ({ ...item })),
          };
          break;
        case "grid":
          item.columns = item.columns.map((item) => ({ ...item }));
          break;
        case "tabs":
          item.tabs = item.tabs.map((item) => ({ ...item }));
          break;
      }

      this.selectedWidget = item;
    },
    onWidgetDelete(index, widgetList) {
      if (!widgetList)
        widgetList = this.widgetList;

      if (widgetList.length - 1 === index) {
        if (index === 0) 
          this.selectedWidget = null;
        else this.selectedWidget = this.widgetList[index - 1];
      } 
      else this.selectedWidget = this.widgetList[index + 1];

      this.$nextTick(() => widgetList.splice(index, 1));
    },
    onWidgetClone(index, widgetList) {
      if (!widgetList)
        widgetList = this.widgetList;

      const key = getKey();

      let item = cloneDeep(widgetList[index]);
      item.key = key;

      let type = item.type;
      if (type === "radio" || type === "checkbox" || type === "select")
        item.options = {
          ...item.options,
          ...item.options.options.map((item) => ({ ...item })),
        };

      widgetList.splice(index, 0, item);
      this.$nextTick(() => this.selectedWidget = item);
    }
  },
};
</script>
<style lang="scss">
@import '@/mixins.scss';
$primary-color: $orange;
$primary-background-color: #ecf5ff;

.main-draggable {
  min-height: 200px;
}

.widget-view {
  position: relative;
  border: 1px dashed rgba(170, 170, 170, 0.7);
  background-color: rgba(236, 245, 255, 0.3);
  margin: 2px;

  .widget-view-layout-drop {
    min-height: 50px;
    border: 1px dashed #ccc;
    background: #fff;
  }

  .widget-view-action {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 28px;
    line-height: 28px;
    background: $primary-color;
    z-index: 1;

    i {
      font-size: 14px;
      color: #fff;
      margin: 0 5px;
      cursor: pointer;
    }
  }

  .widget-view-drag {
    position: absolute;
    left: -2px;
    bottom: 0;
    height: 28px;
    line-height: 28px;
    background: $primary-color;
    z-index: 1;

    i {
      font-size: 14px;
      color: #fff;
      margin: 0 5px;
      cursor: move;
    }
  }

  &:hover {
    background: $primary-background-color;
    outline: 1px solid $primary-color;
    outline-offset: 0px;

    &.active {
      outline: 2px solid $primary-color;
      border: 1px solid $primary-color;
      outline-offset: 0;
    }

    .widget-view-drag {
      display: block;
    }
  }

  &.active {
    outline: 2px solid $primary-color;
    border: 1px solid $primary-color;
  }

  &.ghost {
    background: $primary-color;
    border: 2px solid $primary-color;
    outline-width: 0;
    height: 3px;
    box-sizing: border-box;
    font-size: 0;
    content: "";
    overflow: hidden;
    padding: 0;
  }

  &:not(.widget-view-layout) {
    &::before {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }
}
</style>