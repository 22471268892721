export function getKey() {
  return Date.parse(new Date()) + "_" + Math.ceil(Math.random() * 99999);
}

export function getLabelOptions() {
  return {
    hideLabel: false,
    labelWidth: null,
    labelVertical: false,
  };
}