<template>
  <a-form layout="vertical">
    <div class="mb-4 font-weight-bold text-break">
      {{$t('formConfig.widget.' + currentType)}}
    </div>

    <a-form-item
      v-if="!isLayoutOrLabel"
      :label="$t('formConfig.property.model')"
    >
      <a-select 
        v-model:value="currentData.name" 
        :options="nameOptionList"
        @change="onModelChange"
      />
    </a-form-item>

    <a-form-item
      v-if="hasOption('innerType')"
      :label="$t('formConfig.property.defaultType')"
    >
      <a-select 
        v-model:value="options.innerType"
        :options="innerTypeOptionList"
        @change="onInnerTypeChange"
      />
    </a-form-item>

    <a-form-item
      v-if="hasOption('type')"
      :label="$t('formConfig.property.defaultType')"
    >
      <a-select
        v-model:value="options.type"
        :options="typeOptionList"
      />
    </a-form-item>

    <a-form-item
      v-if="!isLayoutOrLabel"
      :label="$t('formConfig.property.label')"
    >
      <a-input 
        v-model:value="currentData.label" 
        :disabled="options.hideLabel"
      />
    </a-form-item>

    <a-form-item 
      v-if="!isLayoutOrLabel"
      :label="$t('formConfig.property.hideLabel')"
    >
      <a-switch v-model:checked="options.hideLabel" />
    </a-form-item>
    
    <a-form-item
      v-if="hasOption('labelWidth')"
      :label="$t('formConfig.property.labelWidth')"
    >
      <a-input 
        v-model:value="options.labelWidth"
        :disabled="options.labelVertical || options.hideLabel"
      />
    </a-form-item>

    <a-form-item
      v-if="hasOption('labelVertical')"
      :label="$t('formConfig.property.labelVertical')"
    >
      <a-switch 
        v-model:checked="options.labelVertical"
        :disabled="options.hideLabel"
      />
    </a-form-item>
    
    <a-form-item
      v-if="hasOption('precision')"
      :label="$t('formConfig.property.precision')"
    >
      <a-input-number v-model:value="options.precision" :precision="0" :min="0" :max="10" :step="1" />
    </a-form-item>

    <a-form-item
      v-if="!isLayoutOrLabel"
      :label="$t('formConfig.property.editableStatuses')"
    >
      <a-select 
        v-model:value="options.editableStatuses" 
        mode="multiple"
        :options="programStatusList"
      />
    </a-form-item>

    <a-form-item
      v-if="hasOption('width')"
      :label="$t('formConfig.property.width')"
    >
      <a-input v-model:value="options.width"></a-input>
    </a-form-item>

    <a-form-item
      :label="$t('formConfig.property.placeholder')"
      v-if="hasOption('placeholder')"
    >
      <a-input v-model:value="options.placeholder"></a-input>
    </a-form-item>

    <a-form-item
      :label="$t('formConfig.property.startPlaceholder')"
      v-if="hasOption('startPlaceholder')"
    >
      <a-input v-model:value="options.startPlaceholder"></a-input>
    </a-form-item>
    <a-form-item
      :label="$t('formConfig.property.endPlaceholder')"
      v-if="hasOption('endPlaceholder')"
    >
      <a-input v-model:value="options.endPlaceholder"></a-input>
    </a-form-item>

    <a-form-item
      v-if="hasOption('clearable')"
      :label="$t('formConfig.property.clearable')"
    >
      <a-switch v-model:checked="options.clearable" />
    </a-form-item>
    
    <template v-if="currentData.type === 'grid'">
      <a-form-item :label="$t('formConfig.property.gutter')">
        <a-input type="number" v-model:value="options.gutter"></a-input>
      </a-form-item>
      <a-form-item :label="$t('formConfig.property.columnOption')">
        <draggable 
          v-if="currentData.columns !== null"
          item-key="index"
          tag="div" 
          v-model="currentData.columns" 
          group="options" 
          ghostClass="ghost" 
          handle=".drag-item"
        >
          <template #item="{ element: item, index }">
            <div>
              <i class="drag-item" style="font-size: 16px;margin: 0 5px;cursor: move;"><i class="fa fa-arrows-v"></i></i>
              <a-input :placeholder="$t('formConfig.property.span')" style="width: 100px;" type="number" v-model:value="item.span" />
              <a-button type="text" @click="onOptionsRemove(index)" class="mb-2">
                <template #icon><i class="fa fa-trash" /></template>
              </a-button>
            </div>
          </template>
        </draggable>
        <div>
          <a-button type="text" @click="onAddColumn">{{$t('formConfig.action.addColumn')}}</a-button>
        </div>
      </a-form-item>
      <a-form-item :label="$t('formConfig.property.justify')">
        <a-select v-model:value="options.justify">
          <a-select-option value="start">{{$t('formConfig.property.justifyStart')}}</a-select-option>
          <a-select-option value="end">{{$t('formConfig.property.justifyEnd')}}</a-select-option>
          <a-select-option value="center">{{$t('formConfig.property.justifyCenter')}}</a-select-option>
          <a-select-option value="space-around">{{$t('formConfig.property.justifySpaceAround')}}</a-select-option>
          <a-select-option value="space-between">{{$t('formConfig.property.justifySpaceBetween')}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('formConfig.property.align')">
        <a-select v-model:value="options.align">
          <a-select-option value="top">{{$t('formConfig.property.alignTop')}}</a-select-option>
          <a-select-option value="middle">{{$t('formConfig.property.alignMiddle')}}</a-select-option>
          <a-select-option value="bottom">{{$t('formConfig.property.alignBottom')}}</a-select-option>
        </a-select>
      </a-form-item>
    </template>

    <template v-if="currentData.type === 'tabs'">
      <a-form-item :label="$t('formConfig.property.tabOption')">
        <draggable 
          v-if="currentData.tabs !== null"
          item-key="index"
          tag="div" 
          v-model="currentData.tabs" 
          group="options" 
          ghostClass="ghost" 
          handle=".drag-item"
        >
          <template #item="{ element: item, index }">
            <div>
              <i class="drag-item" style="font-size: 16px;margin: 0 5px;cursor: move;"><i class="fa fa-arrows-v"></i></i>
              <a-input :placeholder="$t('formConfig.property.tabName')" style="width: 100px;" type="text" v-model:value="item.title" />
              <a-button type="text" @click="onOptionsRemove(index)" class="mb-2">
                <template #icon><i class="fa fa-trash" /></template>
              </a-button>
            </div>
          </template>
        </draggable>
        <div>
          <a-button type="text" @click="onAddTab">{{$t('formConfig.action.addTab')}}</a-button>
        </div>
      </a-form-item>
    </template>

    <template v-if="currentData.type === 'label'">
      <a-form-item :label="$t('formConfig.property.value')">
        <a-textarea v-model:value="currentData.label" />
      </a-form-item>

      <a-form-item :label="$t('formConfig.property.bold')">
        <a-switch v-model:checked="options.bold" />
      </a-form-item>

      <a-form-item :label="$t('formConfig.property.italic')">
        <a-switch v-model:checked="options.italic" />
      </a-form-item>
    </template>
  </a-form>
</template>

<script>
import api from "@/api";
import Draggable from "vuedraggable";
import useSharedState from "./js/shared-state";
import { getTypeName, getReferenceType } from "@/helpers/form-generator";
export default {
  name: "sp-form-designer-item-config",
  components: {
    Draggable
  },
  data() {
    return {
      validator: {
        type: null,
        required: null,
        pattern: null,
        range: null,
        length: null,
      },
      programStatusList: []
    };
  },
  setup() {
    const { selectedWidget, fieldList } = useSharedState();
    return {
      currentData: selectedWidget,
      fieldList
    };
  },
  computed: {
    options() {
      return this.currentData.options;
    },
    nameOptionList() {
      const type = this.currentData.type;
      return this.fieldList
        .filter(x => x.formComponents.some(s => s === type || s.startsWith(type + ":")))
        .map(x => ({ label: x.customName, value: x.systemName}));
    },
    currentType() {
      return getTypeName(this.currentData.type);
    },
    isLayoutOrLabel() { 
      return this.currentData.isLayout || this.currentData.type === "label";
    },
    typeOptionList() {
      if (!this.currentData.name) 
        return [];
      const type = this.currentData.type;
      return this.fieldList
        .find(x => x.systemName === this.currentData.name)
        .formComponents
        .filter(x => getTypeName(x) === type || getTypeName(x) === getTypeName(type))
        .map(x => {
          let innerType = getReferenceType(x);
          return {
            label: innerType,
            value: innerType,
          };
        });
    },
    innerTypeOptionList() {
      return [{
        label: 'На базе 9 класса',
        value: 'DevelopmentTermNine'
      }, {
        label: 'На базе 11 класса',
        value: 'DevelopmentTermEleven'
      }]
    }
  },
  methods: {
    hasOption(name) {
      return Object.keys(this.currentData.options).indexOf(name) >= 0;
    },
    onOptionsRemove(index) {
      if (this.currentData.type === "grid")
        this.currentData.columns.splice(index, 1);
      else if (this.currentData.type === "tabs")
        this.currentData.tabs.splice(index, 1);
      else
        this.currentData.options.options.splice(index, 1);
    },
    onModelChange(_value, option) {
      this.currentData.label = option.label;
      let components = this.fieldList
        .find(x => x.systemName === this.currentData.name)
        .formComponents;
      this.currentData.type = components.find(x => x.startsWith(this.currentData.type + ":")) ?? this.currentData.type;
    },
    onInnerTypeChange(_value, option) {
      this.currentData.label = option.label;
    },
    onAddColumn() {
      this.currentData.columns.push({
        span: "",
        list: [],
      });
    },
    onAddTab() {
      this.currentData.tabs.push({
        title: "",
        list: [],
      });
    },
  },
  async mounted() {
    let statuses = await api.programStatus.getAll();
    this.programStatusList = statuses.map(x => ({ value: x.id, label: x.title }));
  }
};
</script>