import { getLabelOptions } from "./utils";
import { itemTypes } from "@/helpers/form-generator";

const basicComponents = [
  {
    type: itemTypes.string,
    options: {
      width: '100%',
      placeholder: '',
      clearable: true,
    }
  },
  {
    type: itemTypes.text,
    options: {
      width: '100%',
      placeholder: ''
    }
  },
  {
    type: itemTypes.number,
    options: {
      width: '',
      precision: 0
    }
  },
  {
    type: itemTypes.boolean,
    options: {}
  },
  {
    type: itemTypes.date,
    options: {
      clearable: true,
      placeholder: '',
    }
  },
  {
    type: itemTypes.datePeriod,
    options: {
      clearable: true,
      startPlaceholder: '',
      endPlaceholder: '',
    }
  },
  {
    type: itemTypes.reference,
    options: {
      clearable: true,
      placeholder: '',
    }
  },
  {
    type: itemTypes.commentList,
    options: {}
  },
  {
    type: itemTypes.files,
    options: {}
  },
  {
    type: itemTypes.subtable,
    options: {
      innerType: null
    }
  },
  {
    type: itemTypes.multipleReference,
    options: {
      innerType: null,
      type: null
    }
  },
  {
    type: itemTypes.dependent,
    options: {
      width: '100%',
    }
  },
  {
    type: itemTypes.dependentSubtable,
    options: {
      innerType: null
    }
  },
  {
    type: itemTypes.dependentReference,
    options: {
      clearable: true,
      placeholder: '',
    }
  },
  {
    type: itemTypes.label,
    noLabelOptions: true,
    options: {
      bold: false,
      italic: false,
    },
  }
  // {
  //   type: 'cascader',
  //   options: {
  //     width: '',
  //     placeholder: '',
  //     clearable: false,
  //     remote: true,
  //     remoteOptions: [],
  //     props: {
  //       value: 'value',
  //       label: 'label',
  //       children: 'children'
  //     },
  //   }
  // }
].map(addBaseProps);

const layoutComponents = [
  {
    type: itemTypes.grid,
    isLayout: true,
    columns: [
      {
        span: 12,
        list: []
      },
      {
        span: 12,
        list: []
      }
    ],
    options: {
      gutter: 0,
      justify: 'start',
      align: 'top'
    },
  },
  {
    type: itemTypes.tabs,
    isLayout: true,
    tabs: [
      {
        title: 'Вкладка',
        list: []
      },
    ],
    options: {},
  },
];

function addBaseProps(obj) {
  obj.label = '';
  if (!obj.noLabelOptions)
    obj.options = Object.assign(obj.options || {}, getLabelOptions());
  return obj;
}


export { 
  basicComponents,
  layoutComponents
};