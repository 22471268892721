<template>
  <div class="widget-view-action">
    <i v-if="canClone" class="fa fa-copy" @click.stop="$emit('clone')"></i>
    <i class="fa fa-trash" @click.stop="$emit('delete')"></i>
  </div>
  <div class="widget-view-drag">
    <i class="fa fa-arrows drag-widget"></i>
  </div>
</template>

<script>
export default {
  name: "sp-form-designer-edit-toolbar",
  emits: ["clone", "delete"],
  props: {
    canClone: { type: Boolean, default: false },
  },
}
</script>