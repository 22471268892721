import { ref } from "vue";
import { getKey, getLabelOptions } from "./utils";

const widgetList = ref([]);
const fieldList = ref([]);
const selectedWidget = ref(null);

function setWidgetList(newWidgetList) {
  widgetList.value = normalizeItems(newWidgetList);
}

function setFieldList(newFieldList) {
  fieldList.value = newFieldList;
}

function normalizeItems(newWidgetList) {
  if (newWidgetList.some(x => !x.key || !x.options)) {
    newWidgetList = newWidgetList.map(item => {
      if (!item.key)
        item.key = getKey();
      if (!item.options)
        item.options = getLabelOptions();
      return item;
    });
  }
  return newWidgetList;
}

export default function useSharedState() {
  return {
    widgetList,
    fieldList,
    selectedWidget,
    setWidgetList,
    setFieldList
  };
}